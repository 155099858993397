import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouteMapState } from './route-map.state';

export const routeMapFeatureKey = 'route-map';
export const selectRouteMapFeature = createFeatureSelector<RouteMapState>(routeMapFeatureKey);
export const selectAllRouteMaps = createSelector(
  selectRouteMapFeature,
  (routeMapState: RouteMapState) => routeMapState.routeMaps
);
export const selectLoadingRouteMaps = createSelector(
  selectRouteMapFeature,
  (routeMapState: RouteMapState) => routeMapState.loadingRouteMaps
);
export const selectRouteMap = createSelector(selectRouteMapFeature, (routeMapState: RouteMapState) => routeMapState.routeMap);
export const selectLoadingRouteMap = createSelector(
  selectRouteMapFeature,
  (routeMapState: RouteMapState) => routeMapState.loadingRouteMap
);
export const selectRouteMapUpdated = createSelector(
  selectRouteMapFeature,
  (routeMapState: RouteMapState) => routeMapState.routeMapUpdated
);
export const selectRouteMapDeleted = createSelector(
  selectRouteMapFeature,
  (routeMapState: RouteMapState) => routeMapState.routeMapDeleted
);
