import { createAction, props } from '@ngrx/store';
import { RouteMap } from './route-map.model';

export enum RouteMapActionType {
  GET_ALL_ROUTE_MAPS = '[Route Map] Get All Route Maps',
  GET_ALL_ROUTE_MAPS_SUCCESS = '[Route Map] Get All Route Maps :: Success',
  GET_ROUTE_MAP = '[Route Map] Get Route Map',
  GET_ROUTE_MAP_SUCCESS = '[Route Map] Get Route Map :: Success',
  CREATE_ROUTE_MAP = '[Route Map] Create Route Map',
  CREATE_ROUTE_MAP_SUCCESS = '[Route Map] Create Route Map :: Success',
  UPDATE_ROUTE_MAP = '[Route Map] Update Route Map',
  UPDATE_ROUTE_MAP_SUCCESS = '[Route Map] Update Route Map :: Success',
  RESET_ROUTE_MAP_UPDATED = '[Route Map] Reset Route Map Updated',
  DELETE_ROUTE_MAP = '[Route Map] Delete Route Map',
  DELETE_ROUTE_MAP_SUCCESS = '[Route Map] Delete Route Map :: Success',
  RESET_ROUTE_MAP_DELETED = '[Route Map] Reset Route Map Deleted',
}

export const getAllRouteMaps = createAction(RouteMapActionType.GET_ALL_ROUTE_MAPS);
export const getAllRouteMapsSuccess = createAction(
  RouteMapActionType.GET_ALL_ROUTE_MAPS_SUCCESS,
  props<{ routeMaps: RouteMap[] }>()
);
export const getRouteMap = createAction(RouteMapActionType.GET_ROUTE_MAP, props<{ id: string }>());
export const getRouteMapSuccess = createAction(RouteMapActionType.GET_ROUTE_MAP_SUCCESS, props<{ routeMap: RouteMap }>());
export const createRouteMap = createAction(RouteMapActionType.CREATE_ROUTE_MAP, props<{ routeMap: RouteMap }>());
export const createRouteMapSuccess = createAction(
  RouteMapActionType.CREATE_ROUTE_MAP_SUCCESS,
  props<{ routeMap: RouteMap }>()
);
export const updateRouteMap = createAction(RouteMapActionType.UPDATE_ROUTE_MAP, props<{ routeMap: RouteMap }>());
export const updateRouteMapSuccess = createAction(
  RouteMapActionType.UPDATE_ROUTE_MAP_SUCCESS,
  props<{ routeMap: RouteMap }>()
);
export const resetRouteMapUpdated = createAction(RouteMapActionType.RESET_ROUTE_MAP_UPDATED);
export const deleteRouteMap = createAction(RouteMapActionType.DELETE_ROUTE_MAP, props<{ id: number }>());
export const deleteRouteMapSuccess = createAction(RouteMapActionType.DELETE_ROUTE_MAP_SUCCESS);
export const resetRouteMapDeleted = createAction(RouteMapActionType.RESET_ROUTE_MAP_DELETED);
